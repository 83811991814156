<div class="bg-white">

  <!-- Header -->
  <header class="absolute inset-x-0 top-0 z-50">
    <div class="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <div class="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
        <div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"></div>
      </div>
      <div class="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
        <div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"></div>
      </div>
      <div class="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p class="text-sm leading-6 text-gray-900">
          <strong class="font-semibold">Web Summit | Lisbon.</strong><svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>Join us from Nov. 13 - 16, 2023 to see what's coming next.
        </p>
        <a href="https://t.me/qentare" class="flex-none rounded-full bg-gray-900 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">Meet us there <span aria-hidden="true">&rarr;</span></a>
      </div>
      <div class="flex flex-1 justify-end">

      </div>
    </div>

    <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div class="flex lg:flex-1">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">QENTARE</span>
          <img class="h-5 w-auto" src="/assets/img/qentare-logo.svg" alt="">
        </a>
      </div>
      <!-- <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400">
          <span class="sr-only">Open main menu</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
      </div> -->
      <!-- <div class="hidden lg:flex lg:gap-x-12">
        <a href="#" class="text-sm font-semibold leading-6 text-white">Product</a>
        <a href="#" class="text-sm font-semibold leading-6 text-white">Features</a>
        <a href="#" class="text-sm font-semibold leading-6 text-white">Marketplace</a>
        <a href="#" class="text-sm font-semibold leading-6 text-white">Company</a>
      </div> -->


      <!-- <div class="flex items-center -space-x-10">


        <div class=" rounded-full flex items-center overflow-hidden bg-gray-900/40  ring-1 ring-inset ring-gray-400/30 pl-2 pr-12 py-1  sm:before:flex-1">
          <div class="flex-none rounded-full bg-green-400/10 p-1  text-green-400">
            <div class="h-2 w-2 rounded-full bg-current"></div>
          </div>
          <div class="flex flex-wrap items-center gap-x-6 gap-y-2">
            <p class="text-sm leading-6 pl-2 text-gray-300">
              Cooming soon
            </p>
          </div>
        </div>
        <button type="button" class="rounded-full bg-indigo-600 cursor-not-allowed px-8 py-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Log in</button>


      </div> -->
    </nav>
    <!-- Mobile menu, show/hide based on menu open state. -->

  </header>
</div>

<main>
  <!-- Hero section -->
  <div class="bg-gray-900">

    <div class="relative isolate pt-14">
      <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: circle(100%)"></div>
      </div>
      <div class="py-16 sm:py-12 lg:pb-40">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto max-w-2xl py-32 sm:py-20 lg:py-32">
            <div class="text-center">
              <h1 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">Hedge against crypto volatility</h1>
              <!--<p class="mt-6 text-lg leading-8 text-gray-300">Qentare introduces real estate as a class of assets for crypto investors, and provides the tools to trade tokenized real estate fast and easy.</p>-->
              <p class="mt-6 text-lg leading-8 text-gray-300">We provide the tools to invest in real-world Real Estate on the blockchain - trade, lend and borrow crypto against actual Real Esate.</p>
              <!--<p class="mt-6 text-lg leading-8 text-gray-300">We provide an opportunity to invest in real estate assets, offering a reliable hedge against the fluctuations of the crypto market.</p>-->
              <div class="mt-10 flex items-center justify-center gap-x-10">

                <!-- <div class="flex items-center -space-x-10">


                  <div class=" rounded-full flex items-center overflow-hidden bg-gray-900/40  ring-1 ring-inset ring-gray-400/30 pl-2 pr-12 py-1  sm:before:flex-1">
                    <div class="flex-none rounded-full bg-green-400/10 p-1  text-green-400">
                      <div class="h-2 w-2 rounded-full bg-current"></div>
                    </div>
                    <div class="flex flex-wrap items-center gap-x-6 gap-y-2">
                      <p class="text-sm leading-6 pl-2 text-gray-300">
                        Cooming soon
                      </p>
                    </div>
                  </div>
                  <button type="button" class="rounded-full bg-indigo-600 cursor-not-allowed px-8 py-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</button>

                </div> -->





                <a href="mailto:contact@qentare.com" class="text-sm font-semibold leading-6 text-white rounded-full bg-indigo-600 px-4 py-2">Get in touch <span aria-hidden="true">→</span></a>
              </div>
            </div>
          </div>

          <!-- Logo cloud -->
          <!-- <div class="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/transistor-logo-white.svg" alt="Transistor" width="158" height="48">
            <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/reform-logo-white.svg" alt="Reform" width="158" height="48">
            <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/tuple-logo-white.svg" alt="Tuple" width="158" height="48">
            <img class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/savvycal-logo-white.svg" alt="SavvyCal" width="158" height="48">
            <img class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/statamic-logo-white.svg" alt="Statamic" width="158" height="48">
          </div> -->
        </div>
      </div>


      <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
        <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
      </div>
    </div>
  </div>


  <!--Feature section-->
  <div class="bg-gray-50">
    <div class="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
      <div class="max-w-3xl mx-auto max-w-2xl lg:text-center mb-8">
        <h2 class="text-4xl font-bold tracking-tight text-gray-900">Diversify your portfolio with low volatility assets</h2>
        <p class="mt-6 text-base leading-7 text-gray-600">
          Don't rely solely on volatile crypto for your investments. QENTARE.COM's real-world Real Estate provides a stable and low volatility addition to your portfolio. With our tokenized Real Estate, you can easily diversify your investments and protect your gains from market fluctuations.
        </p>


      </div>


      <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16 mt-16">
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
              </svg>

            </div>
            Transparent and secure
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            QENTARE.COM's use of NFTs as proof of ownership provides a transparent and secure way to invest in actual Real Estate. QENTARE.COM engages top audit firms to comprehensively audit the Real Estate assets on its books, reinforcing the ongoing security and reliability of its product offering, and the safety of your investment.          </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
              </svg>

            </div>
            Low-cost and fast transactions
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Conducting traditional Real Estate transactions is time consuming, often incurring substantial fees and expenses, and can be susceptible to legal complexities and potential fraudulent activities. QENTARE.COM offers safe, instant, low-cost crypto transactions, for you to invest in actual Real Estate with ease.            </dd>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
              </svg>

            </div>
            Expert management
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Forget about the hasle of managing rental properties or handling tenants. Investing via QENTARE.COM's tokenized Real Estate helps diversify your portfolio without introducing new chores and headaches. Our portfolio of low-volatility yield-bearing managed assets serves as a stable foundation for your investments.            </dd>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">


              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
              </svg>


            </div>
            Diversified portfolio
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            By investing in QENTARE.COM's tokenized Real Estate, you can diversify your portfolio and protect your gains from market fluctuations. Our low-volatility assets provide a stable addition to your portfolio, making QENTARE.COM a smart choice for savvy investors.
          </dd>
        </div>
      </dl>
    </div>
  </div>

  <div class="overflow-hidden bg-gray-900 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">


        <div class="lg:pr-8 lg:pt-4">
          <div class="lg:max-w-lg">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500">
              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
              </svg>

            </div>
            <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">List of audit reports on real estate assets</p>
            <p class="mt-6 text-lg leading-8 text-gray-300">Gain peace of mind with our list of audit reports, showcasing the security and legitimacy of our real estate assets.</p>
            <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">


              <div class="flex p-6 ring-1 ring-white/10 hover:ring-white/20 rounded-lg flex-col">
                <dt class="text-base font-semibold leading-7 text-white">
                  <div class="flex pb-4">
                    <span class="inline-flex items-center rounded-md bg-indigo-400/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30">Publishing soon</span>

                  </div>
                  Audit reports on the real estate assets under QENTARE.COM’s management prepared by top audit firms.
                </dt>
                <!-- <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                  <p class="flex-auto"></p>
                  <p class="mt-6">
                    <a href="#" class="text-sm font-semibold leading-6 text-indigo-400">Learn more <span aria-hidden="true">→</span></a>
                  </p>
                </dd> -->
              </div>

            </dl>
          </div>
        </div>

        <div class="lg:pr-8 lg:pt-4">
          <div class="lg:max-w-lg">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500">
              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
              </svg>

            </div>
            <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">List of audit reports on platform security</p>
            <p class="mt-6 text-lg leading-8 text-gray-300">Discover our commitment to platform security through a comprehensive list of audit reports, ensuring the protection of your investments.</p>
            <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">


              <div class="flex p-6 ring-1 ring-white/10 hover:ring-white/20 rounded-lg flex-col">
                <dt class="text-base font-semibold leading-7 text-white">
                  <div class="flex pb-4">
                    <span class="inline-flex items-center rounded-md bg-indigo-400/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30">Publishing soon</span>

                  </div>
                  Security, compliance, and code audit reports on QENTARE.COM’s secure tech products and practices prepared by top security audit firms.
                </dt>
                <!-- <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                  <p class="flex-auto"></p>
                  <p class="mt-6">
                    <a href="#" class="text-sm font-semibold leading-6 text-indigo-400">Learn more <span aria-hidden="true">→</span></a>
                  </p>
                </dd> -->
              </div>

            </dl>
          </div>
        </div>




      </div>


    </div>
  </div>

  <div class="bg-white py-56 md:py-32">
    <div class="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-5">
      <div class="max-w-2xl xl:col-span-2">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About the team</h2>
        <p class="mt-6 text-lg leading-8 text-gray-600">
          With a diverse range of backgrounds and expertise, we work together to provide you with the best possible investment experience.
        </p>
      </div>
      <ul role="list" class="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
        <li class="flex flex-col gap-10 pt-12 sm:flex-row">
          <img class="h-32 w-32 rounded-full" src="/assets/img/roma.png" alt="">
          <div class="max-w-xl flex-auto">
            <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900">Roman Iftodi</h3>
            <p class="text-base leading-7 text-gray-600">Co-Founder / CEO</p>
            <p class="mt-6 text-base leading-7 text-gray-600">
              CEO of a successful DeFi platform 10+ years in IT for the blockchain company.Senior Lead Engineer with 10+ year in traditional IT development and Blockchain.
            </p>
            <!-- <ul role="list" class="mt-6 flex gap-x-6">
              <li>
                <a href="#" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Twitter</span>
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="#" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">LinkedIn</span>
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clip-rule="evenodd" />
                  </svg>
                </a>
              </li>
            </ul> -->
          </div>
        </li>

        <li class="flex flex-col gap-10 pt-12 sm:flex-row">
          <img class="h-32 w-32 rounded-full" src="/assets/img/tom.png" alt="">
          <div class="max-w-xl flex-auto">
            <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900">Tom Bean</h3>
            <p class="text-base leading-7 text-gray-600">Co-Founder / DeFi OG</p>
            <p class="mt-6 text-base leading-7 text-gray-600">
              Founded a crypto platform for lending, borrowing, trading and yield farming. $2B TVL, successful transition to DAO. 20+ years as a technology leader.
            </p>
            <!-- <ul role="list" class="mt-6 flex gap-x-6">
              <li>
                <a href="#" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Twitter</span>
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="#" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">LinkedIn</span>
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clip-rule="evenodd" />
                  </svg>
                </a>
              </li>
            </ul> -->
          </div>
        </li>

        <li class="flex flex-col gap-10 pt-12 sm:flex-row">
          <img class="h-32 w-32 rounded-full" src="/assets/img/robert.png" alt="">
          <div class="max-w-xl flex-auto">
            <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900">Robert Nurnberger</h3>
            <p class="text-base leading-7 text-gray-600">CFO</p>
            <p class="mt-6 text-base leading-7 text-gray-600">
              Transportation industry player €3B+ business volume. 30 years in business and management roles in several high growth technology businesses.
            </p>
            <!-- <ul role="list" class="mt-6 flex gap-x-6">
              <li>
                <a href="#" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Twitter</span>
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="#" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">LinkedIn</span>
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clip-rule="evenodd" />
                  </svg>
                </a>
              </li>
            </ul> -->
          </div>
        </li>

        <li class="flex flex-col gap-10 pt-12 sm:flex-row">
          <img class="h-32 w-32 rounded-full" src="/assets/img/andrei.png" alt="">
          <div class="max-w-xl flex-auto">
            <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900">Andrei Vieru</h3>
            <p class="text-base leading-7 text-gray-600">Co-Founder / CTO</p>
            <p class="mt-6 text-base leading-7 text-gray-600">
              CTO of a successful DeFi platform, Founder and CEO of an IT service provider company, 10+ years in IT for the financial sector.              </p>
            <!-- <ul role="list" class="mt-6 flex gap-x-6">
              <li>
                <a href="#" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Twitter</span>
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="#" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">LinkedIn</span>
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clip-rule="evenodd" />
                  </svg>
                </a>
              </li>
            </ul> -->
          </div>
        </li>




        <!-- More people... -->
      </ul>
    </div>
  </div>

</main>

<!--
This example requires some changes to your config:

```
// tailwind.config.js
module.exports = {
  // ...
  plugins: [
    // ...
    require('@tailwindcss/forms'),
  ],
}
```
-->

<div class="bg-white py-16 sm:py-24">
  <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
    <div class="relative isolate flex flex-col gap-10 overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:flex-row xl:items-center xl:py-32">
      <h2 class="max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl xl:max-w-none xl:flex-auto">Get the investment pitch deck.</h2>
      <form class="w-full max-w-md">
        <div class="flex gap-x-4">
          <label for="name" class="sr-only">Name</label>
          <input id="name" [(ngModel)]="name" name="name" type="text" required class="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6" placeholder="Name">
          <label for="email-address" class="sr-only">Email</label>
          <input id="email-address" [(ngModel)]="email" name="email" type="email" autocomplete="email" required class="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6" placeholder="Email">
          <button type="submit" (click)="addSubscription();" class="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">{{ buttonText }}</button>
        </div>
        <p class="mt-4 text-sm leading-6 text-gray-300">We care about your data. Read our <a href="/privacy" class="font-semibold text-white">privacy&nbsp;policy</a>.</p>
      </form>
      <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2" aria-hidden="true">
        <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
        <defs>
          <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(512 512) rotate(90) scale(512)">
            <stop stop-color="#7775D6" />
            <stop offset="1" stop-color="#E935C1" stop-opacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  </div>
</div>



<footer class="bg-white">
  <div class="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
    <nav class="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
      <div class="pb-6 text-center">
        <a href="/privacy" class="text-sm leading-6 text-gray-600 hover:text-gray-900">Privacy</a>
      </div>
      <div class="pb-6 text-center">
        <a href="/terms" class="text-sm leading-6 text-gray-600 hover:text-gray-900">Terms</a>
      </div>

    </nav>
    <div class="mt-10 flex justify-center space-x-10">
      <a href="https://t.me/qentare" target="_blank" class="text-gray-400 hover:text-gray-500">
        <span class="sr-only">Telegram</span>
        <svg class="h-6 w-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clip-path="url(#clip0_5318_3805)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12.43 8.85893C11.2628 9.3444 8.93014 10.3492 5.43189 11.8733C4.86383 12.0992 4.56626 12.3202 4.53917 12.5363C4.49339 12.9015 4.95071 13.0453 5.57347 13.2411C5.65818 13.2678 5.74595 13.2954 5.83594 13.3246C6.44864 13.5238 7.27283 13.7568 7.70129 13.766C8.08994 13.7744 8.52373 13.6142 9.00264 13.2853C12.2712 11.079 13.9584 9.96381 14.0643 9.93977C14.139 9.92281 14.2426 9.90148 14.3128 9.96385C14.3829 10.0262 14.376 10.1443 14.3686 10.176C14.3233 10.3691 12.5281 12.0381 11.5991 12.9018C11.3095 13.171 11.1041 13.362 11.0621 13.4056C10.968 13.5033 10.8721 13.5958 10.78 13.6846C10.2108 14.2333 9.78391 14.6448 10.8036 15.3168C11.2936 15.6397 11.6858 15.9067 12.0769 16.1731C12.5042 16.4641 12.9303 16.7543 13.4816 17.1157C13.6221 17.2077 13.7562 17.3034 13.8869 17.3965C14.3841 17.751 14.8307 18.0694 15.3826 18.0186C15.7032 17.9891 16.0345 17.6876 16.2027 16.7884C16.6002 14.6631 17.3816 10.0585 17.5622 8.16097C17.578 7.99473 17.5581 7.78197 17.5422 7.68857C17.5262 7.59518 17.4928 7.46211 17.3714 7.3636C17.2276 7.24694 17.0056 7.22234 16.9064 7.22408C16.455 7.23203 15.7626 7.47282 12.43 8.85893Z" fill="currentColor"/>
          </g>
          <defs>
            <clipPath id="clip0_5318_3805">
              <rect width="24" height="24" fill="currentColor"/>
            </clipPath>
          </defs>
        </svg>
      </a>

      <a href="https://twitter.com/qentare" target="_blank" class="text-gray-400 hover:text-gray-500">
        <span class="sr-only">Twitter</span>
        <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      </a>
      <a href="https://medium.com/@qentare" target="_blank" class="text-gray-400 hover:text-gray-500">
        <span class="sr-only">Medium</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
          <path d="M13.5374 11.8277C13.5374 15.5984 10.5069 18.6552 6.76884 18.6552C3.03073 18.6552 0 15.5977 0 11.8277C0 8.05767 3.0305 5 6.76884 5C10.5072 5 13.5374 8.05698 13.5374 11.8277Z" fill="currentColor"/>
          <path d="M20.9642 11.8277C20.9642 15.377 19.4489 18.2554 17.5797 18.2554C15.7106 18.2554 14.1953 15.377 14.1953 11.8277C14.1953 8.27834 15.7103 5.3999 17.5795 5.3999C19.4487 5.3999 20.9639 8.27742 20.9639 11.8277" fill="currentColor"/>
          <path d="M24.0015 11.8276C24.0015 15.0069 23.4686 17.5858 22.8112 17.5858C22.1537 17.5858 21.6211 15.0076 21.6211 11.8276C21.6211 8.64758 22.1539 6.06934 22.8112 6.06934C23.4684 6.06934 24.0015 8.64735 24.0015 11.8276Z" fill="currentColor"/>
        </svg>
      </a>
      <a href="mailto:contact@qentare.com" class="text-gray-400 hover:text-gray-500">
        <span class="sr-only">YouTube</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
          <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
          <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
        </svg>

      </a>
    </div>
    <p class="mt-10 text-center text-xs leading-5 text-gray-500">&copy; 2022-2023 QENTARE, Inc. All rights reserved.</p>
  </div>
</footer>
