import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  public email: string;
  public name: string;
  public buttonText = 'Send me the deck';
  private readonly _http: HttpClient;


  constructor(http: HttpClient) {
    this._http = http;
  }

  ngOnInit() {
  }

  public addSubscription() {
    this._http.post(
      // 'https://qentare.com/api/v1/users',
      '/api/v1/users',
      JSON.stringify({email: this.email, name: this.name, subscriptionType: 'SUBSCRIPTION'}),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).subscribe(data => {
      this.name = '';
      this.email = '';
      this.buttonText = 'Deck sent to email';
      console.log('Sent')
    });
  }

}
